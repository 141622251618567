import React from "react";
import { Link } from "gatsby";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Markdown from 'react-markdown';
import {customColor} from "../../lib/helpers";
import style from "./Content.module.css";

const BigHeadline = ({ copy, textAlign, displayWidth, sectionColor, slug }) => {
    let backgroundColor = customColor(sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : null

    return (
        <section className={`uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-${displayWidth} ${textAlign ? "uk-text-center" : ""}`}>
                <Markdown
                    source={copy}
                    escapeHtml={false}
                />
            </div>
        </section>
    );
}

export default BigHeadline;
