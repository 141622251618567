import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import * as Markdown from 'react-markdown';
import { Video } from "../Video";
import style from "./Hero.module.css";

const HeroDefault = ({data, locale}) => {

    const {
        headline,
        subHeadline,
        imageFile,
        videoPath,
        sectionId: slug
    } = data
    const leader = subHeadline ? (subHeadline.childMarkdownRemark ? subHeadline.childMarkdownRemark.html : subHeadline) : ""

    const defaultCTAImage = useStaticQuery(graphql`
        query defaultCTAImage {
          allContentfulHeroCta {
            edges {
              node {
                sectionId
                imageFile {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `);
    const img = defaultCTAImage.allContentfulHeroCta.edges.find(d => d.node.sectionId === slug)

    return (
        <section id={slug} style={{borderBottom: "1px solid #ebebeb"}} className={`uk-section`}>
            <div className="uk-container">
                <div className="uk-grid uk-grid-small" data-uk-grid>
                    <div className={`${style.heroCopy} ${style.defaultHeroText} uk-width-1-2@m uk-flex uk-flex-column uk-flex-center`}>
                        <h1>{headline}</h1>
                        <div className={`subtitle-text`}>
                            <Markdown
                                source={leader}
                                escapeHtml={false}
                            />
                        </div>
                    </div>

                    <div className="uk-width-expand">
                        {
                            videoPath ? <Video videoSrcURL={videoPath} />
                            :
                            <Img
                                fluid={img.node.imageFile.localFile.childImageSharp.fluid}
                                alt={headline}
                            />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroDefault;
