import React from "react";
import {customColor} from "../../lib/helpers";
import style from "./Content.module.css";

const MediaSection = ({description, mediaPath, templateLayout, sectionColor, locale}) => {
    let backgroundColor = customColor(sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : null
    return (
        <section className={`uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-${templateLayout}`}>
                <div style={{position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden", zIndex: "5"}} className="uk-box-shadow-large">
                    <iframe
                        style={{position: "absolute", top: "0", left: "0", height: "100%", width: "100%"}}
                        src={mediaPath}
                        frameBorder="0"
                    />
                </div>
            </div>
        </section>
    )
}

export default MediaSection;
