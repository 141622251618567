import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import {HtmlVideo} from "../Video";
import * as Markdown from 'react-markdown';
import style from "./Content.module.css";

const TwoColumnContent = ({ title, copy, image, textAlign, displayWidth, sectionColor, slug, locale }) => {


    const sectionImage = useStaticQuery(graphql`
        query sectionImage {
          allContentfulSectionItem {
            edges {
              node {
                slug
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `);

    const data = sectionImage.allContentfulSectionItem.edges.find(d => d.node.slug === slug)

    return (
        <section className={`uk-section`}>
            <div className={`uk-container uk-container-${displayWidth}`}>
                <div className="uk-grid uk-grid-large" data-uk-grid>
                    <div className={`uk-width-3-5@m ${textAlign && !textAlign ? "uk-flex-right" : "uk-flex-left"}`}>
                    {data.node.image.localFile.childImageSharp ?
                        <Img
                            fluid={data.node.image.localFile.childImageSharp.fluid}
                            alt={title}
                            objectFit="cover"
                            objectPosition="50% 50%"
                             />
                    :
                        <HtmlVideo videoSrcURL={image.fields.file.url} />
                    }
                     </div>
                     <div className={`uk-width-expand uk-flex uk-flex-middle ${textAlign && textAlign && "uk-flex-first@m"}`}>
                        <div>
                            <h2>{title}</h2>
                            <div>
                                <Markdown
                                    source={copy}
                                    escapeHtml={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TwoColumnContent;
