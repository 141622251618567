import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

export const ImageCarousel = ({headline, subHeadline, displayWidth, images, slug}) => {
    return (
        <>
        <div className="uk-container uk-container-small">
            {headline ?
                <div className="uk-text-center uk-margin-large-bottom">
                    <h2>{headline}</h2>
                    {subHeadline ? <h4>{subHeadline}</h4> : null}
                </div>
                :
                null
            }
        </div>
        <div className={`uk-container uk-container-${displayWidth} image-collection`}>
            <div className="uk-slider-container" data-uk-slider="autoplay: true; sets: true; autoplay-interval: 4000;">
                <div className="client-grid uk-grid uk-grid-large uk-slider-items uk-child-width-1-3 uk-child-width-1-4@s uk-child-width-1-6@m" data-uk-grid>
                    <Images slug={slug} />
                </div>
            </div>
        </div>
        </>
    )
}

export const ImageGrid = ({headline, subHeadline, displayWidth, images, slug}) => {
    return (
        <div className={`uk-container uk-container-${displayWidth}`}>
            {headline ?
                <div className="uk-text-center uk-margin-large-bottom">
                    <h2>{headline}</h2>
                    {subHeadline ? <h4>{subHeadline}</h4> : null}
                </div>
                :
                null
            }
            <div className="uk-margin-auto-left uk-margin-auto-right">
                <div className={`client-grid uk-grid uk-grid-large uk-child-width-1-3 ${images.length < 4 ? "uk-child-width-1-"+images.length : "uk-child-width-1-4@m"}`}
                    data-uk-scrollspy={`cls: uk-animation-slide-bottom-medium; target: div; delay: ${20};`}
                    data-uk-grid>
                    <Images slug={slug} />
                </div>
            </div>
        </div>
    )
}

const Images = ({slug}) => {
  const collectionImages = useStaticQuery(graphql`
      query collectionImages {
        allContentfulImageCollection {
          edges {
            node {
              collectionIdentity
              images {
                title
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
  const data = collectionImages.allContentfulImageCollection.edges.find(d => d.node.collectionIdentity === slug)

  return (
      data.node.images.map((image, i) =>
          <div key={`${data.node.collectionIdentity}-${i}`}>
              <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.title}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  className="uk-border-rounded"
                   />
          </div>
      )
  )
}

export default Images;
