import React from "react";
import * as Markdown from 'react-markdown';
import style from "./Testimonials.module.css";

const Testimonials = ({cards}) => {
    return (
        <div className="uk-container uk-container-small uk-child-width-2-3@m uk-margin-large-bottom uk-margin-large-top">
            <div className="uk-position-relative uk-text-center" data-uk-slider="autoplay: true; pause-on-hover: false;">
                <ul className={`${style.testimonialCards} uk-slider-items uk-child-width-1-1 uk-flex-middle`}>
                    {cards.map((card, i) =>
                        <li
                            key={card.fields.slug}>
                            <div className={``}>
                                <div className={`${style.testimonialQuote}`}>
                                    <Markdown
                                        source={card.fields.copy}
                                        escapeHtml={false}
                                    />
                                </div>
                                <p className="uk-text-small uk-text-center uk-text-bold uk-margin-remove">{card.fields.source}</p>
                                <p className="uk-text-small uk-text-muted uk-text-center uk-margin-remove">{card.fields.role}</p>
                            </div>
                        </li>
                    )}
                </ul>
                <div className="uk-margin-top">
                    <ul className="uk-slider-nav uk-dotnav uk-flex-center"></ul>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;
