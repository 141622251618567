import React from "react";
import { Link } from "gatsby";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Markdown from 'react-markdown';
import style from "./Content.module.css";

const CareerPostListItem = ({ title, jobSummary, slug }) => {
    return (
        <section className={`uk-section uk-section-small`}>
            <div className={`uk-container uk-container-xsmall`}>
                <h3>{title}</h3>
                <Markdown
                    source={jobSummary}
                    escapeHtml={false}
                />
                <Link className={`uk-link`} to={`/careers/${slug}`}><strong>Apply now</strong></Link>
            </div>
        </section>
    );
}

export default CareerPostListItem;
