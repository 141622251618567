import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
import * as Markdown from 'react-markdown';
import {customColor} from "../../lib/helpers";
import style from "./CustomSection.module.css";

const CustomSection = ({ title, copy, image, sectionColor, textAlign, slug, locale }) => {
    let backgroundColor = customColor(sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : "uk-background-default"
    const img = image.fields.file.url;

    const customSectionImage = useStaticQuery(graphql`
        query customSectionImage {
          allContentfulCustomSection {
            edges {
              node {
                slug
                image {
                  title
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `);
    const data = customSectionImage.allContentfulCustomSection.edges.find(d => d.node.slug === slug)

    return (
        <section id={slug} className={`${style.customSection} uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-small ${backgroundColor}`}>
                <div className="uk-grid uk-grid-collapse" data-uk-grid>
                    <div className={`uk-width-3-5@m ${textAlign && textAlign === 'Text Left' ? "uk-flex-right" : "uk-flex-left"}`}>
                        <div className={`uk-width-1-1 uk-text-left`}>
                        <Img
                            fluid={data.node.image.localFile.childImageSharp.fluid}
                            alt={title}
                            objectFit="contain"
                            objectPosition="50% 50%"
                            imgStyle={{width: "100%"}}
                            style={{width: "100%"}}
                             />
                        </div>
                     </div>
                     <div className={`uk-width-expand uk-flex uk-flex-middle ${textAlign && textAlign === 'Text Left' && "uk-flex-first@m"}`}>
                        <div>
                            <h2>{title}</h2>
                            <div>
                                <Markdown
                                    source={copy}
                                    escapeHtml={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CustomSection;
