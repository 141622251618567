import React, {useEffect} from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// import Img from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Markdown from 'react-markdown';
import {customColor, collectionDisplay} from "../../lib/helpers";
import Testimonials from "./Testimonials";
import style from "./Content.module.css";
import eye from "../../images/icons/Eye.png";

const CardCollection = ({ headline, subHeadline, cards, slug, displayFormat, displayWidth, sectionColor, ...props }) => {

    const cardImages = useStaticQuery(graphql`
        query cardImages {
            allContentfulCardCollection {
                edges {
                    node {
                        slug
                        cards {
                            ...on ContentfulBlogPost {
                                slug
                                createdAt
                                title
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                heroImage {
                                    title
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        publicURL
                                    }
                                }
                            }
                            ...on ContentfulSectionItem {
                                id
                                slug
                                title
                                createdAt
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                image {
                                    title
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        publicURL
                                    }
                                }
                            }
                            ...on ContentfulTestimonial {
                                id
                                slug
                                source
                                role
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                    copy
                                }
                            }
                        }
                    }
                }
            }
        }
      `);

    const dis = collectionDisplay(displayFormat)
    const data = cardImages.allContentfulCardCollection.edges.find(d => d.node.slug === slug)
    let backgroundColor = customColor(sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : null
    const collection = cards.map(c => c.fields)

    useEffect(
        () => {
            const a = document.querySelectorAll('A')
            for (let val of Object.values(a)) {
                if(val.getAttribute('title') && val.getAttribute('title') === 'card link') {
                    // val.classList.add('uk-button', 'uk-button-text', 'cta-button')
                    val.removeAttribute('title')
                }
            }
        }, []
    )

    return (
        <section className={`uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-${displayWidth} uk-position-relative`}>
                {headline ?
                    <div className="uk-text-center uk-margin-large-top uk-margin-medium-bottom uk-margin-auto-left uk-margin-auto-right uk-container-xsmall">
                        <h2 className="uk-margin-remove-top uk-margin-small-bottom uk-display-block">
                            {headline}
                        </h2>
                        {subHeadline ? <p className="uk-h4 uk-margin-remove-top">{subHeadline}</p> : null}
                    </div>
                : null
                }
            </div>
            {cards[0].sys.contentType.sys.id === "testimonial" ?
            <Testimonials cards={cards} />

            :

            <div className={`uk-container uk-container-${displayWidth} uk-position-relative`}>
                <div data-uk-slider={dis.carousel ? "autoplay: true" : "false"}>
                    <div
                        className={`${dis.carousel ? "uk-slider-items" : ""} ${style.cardCollection} uk-grid ${dis.count ? "uk-child-width-1-1" : (collection.length <= 2 || displayWidth === "xsmall") ? "uk-child-width-1-2@m" : "uk-child-width-1-3@m"} uk-grid-match`}
                        data-uk-grid
                        data-uk-height-match="target: .uk-card-body">
                        {cards.map((card, i) =>
                        <div key={card.fields.slug}
                            className={`${collection.length <= 2 ? style.cardItemLarge : (displayWidth === "small" || displayWidth === "xsmall") ? style.cardItemSmall : style.cardItem} uk-card uk-card-small`}>
                            {data.node.cards[i].image ?
                            <div className="uk-card-media-top">
                                 <Img
                                     fluid={data.node.cards[i].image.localFile.childImageSharp.fluid}
                                     alt={card.fields.image.fields.title}
                                     objectFit="cover"
                                     objectPosition="50% 50%"
                                     className="uk-border-rounded"
                                      />
                            </div>
                            : null}
                            <div
                                className={`uk-card-body`}
                                >
                                {card.fields.title ?
                                <h3 className="uk-margin-remove-vertical">
                                        {card.fields.title}
                                </h3>
                                : null}
                                {card.fields.copy ?
                                <Markdown
                                    source={card.fields.copy}
                                    escapeHtml={false}
                                />
                                : null}
                                {card.sys.contentType.sys.id === "newsPost" ?
                                    <strong><Link to={`/news/press/${card.fields.slug}`}>Read more</Link></strong>
                                :null}
                                {card.sys.contentType.sys.id === "blogPost" ?
                                    <strong><Link to={`/news/article/${card.fields.slug}`}>Read more</Link></strong>
                                :null}
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="uk-margin-large-top">
                        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                    </div>
                </div>
            </div>
            }
        </section>
    );
}

export default CardCollection;
