import { Link, useStaticQuery, graphql } from "gatsby";
// import Img from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill"
import React from "react"
import * as Markdown from 'react-markdown';
import {customColor} from "../../lib/helpers";
import style from "./TabbedContent.module.css";

const TabNav = ({data}) =>
    <div className="uk-width-2-5 uk-margin-auto-left uk-margin-auto-right uk-flex-center uk-visible@s">
        <ul className="uk-margin-remove-vertical uk-padding-remove uk-flex-nowrap uk-flex-center" data-uk-tab>
            {data.map((item, id) =>
            <li
                key={`${item.slug}-${id}`}
                className={`uk-button uk-button-text`}
                data-uk-slideshow-item={id}>
                <a href={`#item${id}`}>{item.shortLabel}</a>
            </li>
            )}
        </ul>
    </div>

const TabItems = ({data}) => {
    return (
        <div className={`${style.slideShowItems} uk-slideshow-items`}>
        {data.map((item, id) =>
        <div key={`${item.slug}-${id}`} className="uk-grid uk-grid-large uk-grid-match" data-uk-grid>
            <div className={`uk-width-3-5@m`}>
            <Img
                fluid={item.image.localFile.childImageSharp.fluid}
                alt={item.image.title}
                objectFit="contain"
                objectPosition="50% 50%"
                style={{height: "100%"}}
                imgStyle={{height: "100%"}}
                 />
             </div>
             <div className={`uk-width-expand`}>
                <div className={style.cardBody}>
                    <h2 className="uk-margin-remove-top uk-margin-small-bottom">{item.title}</h2>
                    <div>
                        <Markdown
                            source={item.copy.childMarkdownRemark.html}
                            escapeHtml={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        )}

        </div>
    )

}
const TabbedContent = ({displayWidth, sectionColor, slug, headline}) => {

    const tabbedContentImage = useStaticQuery(graphql`
        query tabbedContentImage {
            allContentfulTabbedContentCollection {
                edges {
                    node {
                        collectionName
                        slug
                        headline
                        displayWidth
                        contentModules {
                            slug
                            title
                            shortLabel
                            copy {
                                childMarkdownRemark {
                                    html
                                }
                            }
                            image {
                                title
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        fluid(maxWidth: 800) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      `);
    const data = tabbedContentImage.allContentfulTabbedContentCollection.edges.find(d => d.node.slug === slug)
    let backgroundColor = customColor(sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : null

    return (
        <section id={slug} className={`${style.tabbedContentContainer} ${backgroundColor ? backgroundColor : ""} uk-section`}>
            <div className={`uk-container uk-container-${displayWidth}`}
                data-uk-slideshow="autoplay: true; autoplay-interval: 5500;">
                {headline ?
                    <h2 className="uk-width-3-4@s uk-text-center uk-margin-auto-left uk-margin-auto-right uk-margin-large-bottom">{headline}</h2>
                 : null}
                <TabNav data={data.node.contentModules} />
                <div className={`uk-container`}>
                    <TabItems data={data.node.contentModules} />
                    <div className="uk-hidden@s">
                        <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-padding"></ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TabbedContent;
