import React from "react"
import HeroDefault from "../Hero/HeroDefault";
import HeroImage from "../Hero/HeroImage";
import HeroVideo from "../Hero/HeroVideo";
import EmbedVideo from "../Hero/EmbedVideo";
import style from "../Hero/Hero.module.css";

const HeroCarousel = ({collectionName, heroCtaModules, slug, ...props}) => {

    const loadHero = hero => {
        switch(hero.templateLayout) {
            case "Hero Image":
                return <HeroImage data={hero} />
            case "Hero Video":
                return <HeroVideo data={hero} />
            default:
                return <HeroDefault data={hero} />
        }
    }

    return (
        <div
            className={`${style.topWrapHeight} hero-carousel-section uk-position-relative uk-visible-toggle uk-container uk-container-expand uk-padding-remove`}
            data-uk-slider="autoplay: true">
            <div className="uk-slider-container">
                <div className="uk-slider-items uk-child-width-1-1">
                    {heroCtaModules.map((item, id) => <div key={item.fields.sectionId}>{loadHero(item.fields)}</div>)}
                </div>
            </div>
            <div className="uk-position-bottom-center uk-margin-large-top uk-position-z-index uk-text-center uk-light">
                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" />
            </div>
        </div>
    )
}

export default HeroCarousel;
